import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { supabase } from './supabase/supabase';
import { Bar } from 'react-chartjs-2'; // Importamos el componente de gráfico de barras
import Chart from 'chart.js/auto';


function App() {
  const [scrolled, setScrolled] = useState(false);
  const [sectores, setSectores] = useState([]);
  const [sectorSeleccionado, setSectorSeleccionado] = useState(null);
  const selectedSectorRef = useRef(null);
  const [selectedIndicador, setSelectedIndicador] = useState('');
  const [indicadores, setIndicadores] = useState([]);
  const [opcionesIndicador, setOpcionesIndicador] = useState([]);
  const [selectedOpciones, setSelectedOpciones] = useState('');
  const [dataGrafico, setDataGrafico] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchSectores = async () => {
      try {
        const { data, error } = await supabase.from('sectores').select('*');
        if (error) {
          throw error;
        }
        setSectores(data);
      } catch (error) {
        console.error('Error al obtener los sectores:', error.message);
      }
    };

    fetchSectores();
  }, []);

  const handleClick = async (nombreSector) => {
    const sector = sectores.find(sector => sector.name === nombreSector);
    if (sector) {
      setSectorSeleccionado(sector);
      console.log("Sector seleccionado:", sector);
      try {
        const { data, error } = await supabase
          .from('rel_sectores_indicadores')
          .select(`*,indicadores(name)`)
          .eq('id_sectores', sector.id);

        if (error) {
          throw error;
        }

        const indicadoresData = data.map((rel) => rel.indicadores.name);
        setIndicadores(indicadoresData);
        console.log("indic", indicadoresData);

      } catch (error) {
        console.error('Error al obtener los indicadores:', error.message);
      }
      if (selectedSectorRef.current) {
        selectedSectorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      console.log("No se encontró el sector en la tabla sectores.");
    }
  };

  const handleIndicadorChange = async (selectValue) => {
    try {
      const { data: Indicador, error } = await supabase
        .from('indicadores')
        .select('id')
        .eq('name', selectValue);

      if (error) {
        throw error;
      }

      if (Indicador.length > 0) {
        const indicadorId = Indicador[0].id;
        setSelectedIndicador(indicadorId);

        try {
          const { data: opcionesInd, error } = await supabase
            .from('rel_indicadores_indicadores_opciones3')
            .select(`*,indicadores_opciones3(name)`)
            .eq('id_indicadores', indicadorId);

          if (error) {
            throw error;
          }

          const opcionesData = opcionesInd.map((rel) => rel.indicadores_opciones3.name);
          setOpcionesIndicador(opcionesData);

        } catch (error) {
          console.error('Error al obtener las opciones de indicadores:', error.message);
        }
      }
    } catch (error) {
      console.error('Error al obtener los indicadores:', error.message);
    }
  };

  const handleOpcionesChange = async (selectValue) => {
    try {
      const { data: Opciones, error } = await supabase
        .from('indicadores_opciones3')
        .select('id')
        .eq('name', selectValue);

      const opcionesId = Opciones[0].id;
      setSelectedOpciones(opcionesId);

      if (error) {
        throw error;
      }

      // Si se seleccionó una opción, obtener los datos para el gráfico
      if (selectedIndicador) {
        const { data: generalData, error } = await supabase
          .from('general')
          .select('meses')
          .eq('id_sectores', sectorSeleccionado.id)
          .eq('id_indicadores', selectedIndicador)
          .eq('id_opciones_indicadores3', opcionesId);

        if (error) {
          throw error;
        }

        const mesesData = generalData.map(item => {
          const mesesParsed = JSON.parse(item.meses);
          const labels = Object.keys(mesesParsed);
          const valores = Object.values(mesesParsed);
          const valoresComoCadenas = valores.map(val => val.toString());
          return { labels, valores: valoresComoCadenas };
        });

        // Preparar los datos para el gráfico
        const data = {
          labels: mesesData[0].labels,
          datasets: [
            {
              label: 'Valores',
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: 'rgba(0,0,0,1)',
              borderWidth: 2,
              data: mesesData[0].valores,
            },
          ],
        };

        setDataGrafico(data);
      }
    } catch (error) {
      console.error('Error al obtener las opciones:', error.message);
    }
  };

  return (
    <div>
      <nav className={`h-[100px] px-10 ${scrolled ? 'scrolled' : ''}`}>
        <div className="logo">
          <img src="./images/logoOf.webp" alt="Logo" />
        </div>
        <ul>
          <li className="dropdown">
            <a href="#">Quiénes Somos &#x25BE;</a>
            <div className="dropdown-content">
              <a href="#">Agenda Comercial</a>
              <a href="#">Alquiler de Ambientes</a>
              <a href="#">Asesoría Legal</a>
            </div>
          </li>
          <li><a href="#">Consejo Directivo</a></li>
          <li><a href="#">Organización</a></li>
          <li className="dropdown">
            <a href="#">Servicios &#x25BE;</a>
            <div className="dropdown-content">
              <a href="#">Agenda Comercial</a>
              <a href="#">Alquiler de Ambientes</a>
              <a href="#">Asesoría Legal</a>
              <a href="#">Capacitaciones</a>
              <a href="#">Certificación de Origen</a>
              <a href="#">Cómprale al Asociado</a>
              <a href="#">Convenios</a>
              <a href="#">Estudios Económicos</a>
              <a href="#">Ferias y Ruedas de Networking</a>
              <a href="#">Organización de Webinars</a>
              <a href="#">Protestos y Moras</a>
            </div>
          </li>
          <li><a href="#">Arbitraje</a></li>
          <li><a href="#">Anúnciate</a></li>
          <li><a href="#">Eventos</a></li>
          <li><a href="#">Contactos</a></li>
        </ul>
      </nav>
      <section className='h-screen relative'>
        <img src='./images/1.png' className="w-full h-full object-cover" />
        <h1 className='absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 '>Indicadores económicos <br /><span>de la Región Arequipa</span></h1>
      </section>
      <section className='relative bg-white flex justify-center items-center'>
        <section id="hero" className="container items-center m-20">
          <div className="row grid grid-cols-3 gap-10 flex items-center self-center content-center place-items-center mt-0 pt-0">
            {sectores.map((sector, index) => (
              <div key={index} className="col-md-4">
                <div className="flex flex-col items-center justify-content-center max-w-full">
                  <a href="#options" className="py-4 btn btn-primario" onClick={() => handleClick(sector.name)}>
                    <div className="flex items-center justify-content-center">
                      <img src={`./images/${sector.imagen}`} alt={sector.name} />
                    </div>
                    <div className="flex items-center justify-content-center">
                      <span>{sector.name}</span>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
      </section>
      {sectorSeleccionado && (
        <section ref={selectedSectorRef} className="relative bg-white grid justify-center m-20">
          <div className="container justify-center h-full">
            <h2 className="text-center">{`Indicadores económicos de ${sectorSeleccionado.name}`}</h2>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3">
              <div className="max-h-screen mt-20">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                  Indicadores
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                    onChange={(e) => handleIndicadorChange(e.target.value)}
                  >
                    <option value="">Selecciona una opción</option>
                    {indicadores.map((indicador) => (
                      <option key={indicador} value={indicador}>
                        {indicador}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 max-h-screen mt-20" htmlFor="grid-state">
                Opciones de indicadores
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  onChange={(e) => handleOpcionesChange(e.target.value)}
                >
                  <option value="">Selecciona una opción</option>
                  {opcionesIndicador.map((opciones) => (
                    <option key={opciones} value={opciones}>
                      {opciones}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          {dataGrafico && (
            <div className="container mx-auto mt-10">
              <Bar data={dataGrafico} />
            </div>
          )}
        </section>
      )}
    </div >
  );
}

export default App;
